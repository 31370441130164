<template>
<div>
  <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="修改厅房时间"
      width="600px"
      @ok="handleSubmit"
  >
    <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
    >
      <a-form-item label="进厅时间">
        <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择时间"
            v-decorator="['to_hall_time', {
              rules: [
                { required: true, message: `请选择进厅时间` },
              ],
              initialValue: data.in_hall_time,
            }]"
        />
      </a-form-item>
      <a-form-item label="出厅时间">
        <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择时间"
            v-decorator="['out_hall_time', {
              rules: [
                { required: true, message: `请选择出厅时间` },
              ],
              initialValue: data.out_hall_time,
            }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</div>
</template>

<script>
import {findHallTimeForm, updateHallTime} from "@/api/order";
import {formatMinuteTime} from "@/utils/time";

export default {
  name: "UpdateHallTime",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'update_hall_time' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findHallTimeForm(this.serviceOrderId, {}).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    closeModal() {
      this.isShow = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.in_hall_time = formatMinuteTime(values.to_hall_time)
          values.out_hall_time = formatMinuteTime(values.out_hall_time)
          if (values.in_hall_time >= values.out_hall_time) {
            this.submitting = false
            this.$warning({
              title: '出厅时间要大于出厅时间',
              content: ''
            })
            return
          }
          updateHallTime(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
